<template>
    <div>
        <div class="formItemBottomBox">
            <div class="formItemInput" @click="passwordFocus">
                {{ password[0] ? passwordType != 'password' ? password[0] : '*' : '' }}
                <div class="formItemInputDesign" v-show="focus && password.length == 0">
                </div>
            </div>
            <div class="formItemInput" @click="passwordFocus">
                {{ password[1] ? passwordType != 'password' ? password[1] : '*' : '' }}
                <div class="formItemInputDesign" v-show="focus && password.length == 1">
                </div>
            </div>
            <div class="formItemInput" @click="passwordFocus">
                {{ password[2] ? passwordType != 'password' ? password[2] : '*' : '' }}
                <div class="formItemInputDesign" v-show="focus && password.length == 2">
                </div>
            </div>
            <div class="formItemInput" @click="passwordFocus">
                {{ password[3] ? passwordType != 'password' ? password[3] : '*' : '' }}
                <div class="formItemInputDesign" v-show="focus && password.length == 3">
                </div>
            </div>
            <div class="formItemInput" @click="passwordFocus">
                {{ password[4] ? passwordType != 'password' ? password[4] : '*' : '' }}
                <div class="formItemInputDesign" v-show="focus && password.length == 4">
                </div>
            </div>
            <div class="formItemInput" @click="passwordFocus">
                {{ password[5] ? passwordType != 'password' ? password[5] : '*' : '' }}
                <div class="formItemInputDesign" v-show="focus && password.length == 5">
                </div>
            </div>
            <input @focus="toFocus" @blur="toBlur" :value="password" @input="changePassword" style="height:0;background:none;width:0;border:0;padding:0;margin:0;outline:0;position:fixed;top:0;left:0;" type="number" onInput="value=value.replace(/[^\d]/g,'').slice(0,6)" :maxlength="6" ref="passwordInput"/>
        </div>
        <div v-if="showTips" style="margin-top:1rem;display:flex;align-items: center">
            <img :src="WarningIcon" style="width:1rem;" />
            <span style="color:#e0153a;font-size:1rem;font-weight:bold;margin-left:.2rem;">{{ $t("WithdrawTips") }}</span>
        </div>
    </div>
</template>

<script>

    import WarningIcon from "@/assets/withdraw/warning_icon.webp";

    export default {
        name:"MobileInput",
        props:{
            password:{ // 密码
                type:String
            }, 
            passwordType:{// 类型
                type:String
            } 
        },
        data(){
            return {
                WarningIcon,
                focus:false,
                focusFlag:false,
                inputValue:"",
                showTips:false,
            }
        },
        methods:{
            changePassword(event){
                this.$emit("update:password",event.target.value);
                if(event.target.value.length >= 6){
                    this.showTips = false;
                    this.$forceUpdate();
                }else{
                    this.showTips = true;
                    this.$forceUpdate();
                }
            },
            toFocus(){
                this.focus = true;
                this.focusFlag = false;
                if(this.password.length < 6){
                    this.showTips = true;
                    this.$forceUpdate();
                }else{
                    this.showTips = false;
                    this.$forceUpdate();
                }
            },
            toBlur(){
                this.focusFlag = true;
                setTimeout(() => {
                    if(this.focusFlag){
                        this.focus = false;
                        this.showTips = false;
                        this.$forceUpdate();
                    }
                },100)
            },
            passwordFocus(){
                this.$nextTick(() => {
                    this.$refs.passwordInput.focus();
                });
            },
        }
    }
</script>

<style scoped>

    @keyframes toggleVisibility {
        0% { opacity: 0; visibility: hidden; }
        50% { opacity: 1; visibility: visible; }
        100% { opacity: 0; visibility: hidden; }
    }

    .formItemInputDesign{
        height:50%;
        width:0.2rem;
        background:#fff;
        animation: toggleVisibility .6s infinite;
    }
    .formItemInput{
        width:calc((100% - 6rem) / 6);
        height:5.5rem;
        background:var(--sub25black);
        border-radius:1rem;
        display:flex;
        align-items: center;
        justify-content: center;
        color:#fff;
        font-size:1.6rem;
        font-weight:bold;
    }

    .formItemBottomBox{
        width:100%;
        margin-top:1rem;
        display:flex;
        align-items: center;
        justify-content: space-between;
    }
</style>