<template>
    <div class="TranscationModal">

        <div class="title">
            <div style="width:2rem"></div>
            <span v-text="'Inserir senha'"></span>
            <svg @click.stop="closeModal" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200"><path d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z" fill="#5e6f8f"></path></svg>
        </div>

        <div class="formBox">
            
            <div class="formItemBox">
                <div class="formItemTop">
                    <span>Confirme a senha</span>
                    <template v-if="passwordType === 'password'">
                        <svg @click="passwordType = 'text'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z" fill="#495669"></path>
                        </svg>
                    </template>
                    <template v-if="passwordType === 'text'">
                        <svg @click="passwordType = 'password'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z" fill="#495669"></path>
                        </svg>
                    </template>
                </div>
            </div>
            <MobileInput :password.sync="password" :passwordType.sync="passwordType"></MobileInput>
        </div>
        
        <div class="setTranscationTips" style="margin-top:2rem">
            Para a seguranca da sua conta, introduza a palavra-passe delevantamento
        </div>
        <div class="formButton" :style="canPush ? '' : 'filter: brightness(80%);'" @click="confirm">
            Congirmar
            <div style="margin-left:0.3rem" v-loading="isPush"></div>
        </div>
    </div>
</template>

<script>

    import { CheckTranscationPassword } from "@/api/deposit.js";
    import MobileInput from "@/components/MobileInput.vue";

    export default {
        name:"ConfirmTranscationmModal",
        data(){
            return {
                password:"",
                passwordType:"password",
                isPush:false,
            }
        },
        props:{
            visible:{
                type:Boolean
            }
        },
        computed:{
            canPush(){
                return this.password.length >= 6;
            }
        },
        methods:{
            confirm(){ // 确认提交
                if(this.canPush){
                    if(!this.isPush){
                        this.isPush = true;
                        let data = {
                            transactionPassword:this.password
                        }
                        CheckTranscationPassword(data).then(res => {
                            if(res.code == 200){
                                this.$emit("successCheck",this.password);
                                this.password = "";
                            }else{
                                this.$message(res.msg);
                            }
                            this.isPush = false;
                        })
                    }
                }
            },
            closeModal(){
                this.password = "";
                this.$emit("update:visible",false);
            },
        },
        components:{
            MobileInput
        }
    }
</script>

<style scoped>

    .title svg{
        width:2rem;
        height:2rem;
    }
    .title path{
        fill:var(--link9)
    }
    
    .title > span {
        color:#fff;
        font-size:1.6rem;
        font-weight:bold;
    }

    .title{
        display:flex;
        align-items: center;
        justify-content: space-between;
    }

    .formButton {
        border-radius: 1rem;
        background: var(--purplebg8);
        height: 5.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: bold;
        font-size:1.7rem;
        margin-top:2rem;
        cursor:pointer;
    }

    .TranscationModal{
        width:100%;
    }
    .setTranscationTips{
        color: var(--purplebg8);
        font-size:1.6rem;
        font-weight:bold;
        word-break: break-all;
        margin-top:1rem;
    }
    .formBox{
        width:100%;
        margin-top:2rem;
    }
    .formItemBox{
        width:100%;
        margin-top:1rem;
    }
    .formItemTop{
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    .formItemTop > span {
        font-size:1.5rem;
        color:#fff;
        font-weight:bold;
    }
    .formItemTop > svg {
        width:2rem;
        height:2rem;
    }
    .formItemTop path {
        fill:#fff;
    }
</style>